import HeroImage from "../../components/HomeComponents/HeroImage/HeroImage";
import Navbar from "../../components/ui/navbar/Navbar";

const Home = () => {
  return (
    <div>
      <a href="#main" className="skip-to-main">Skip to main content</a>
      <Navbar />
      <div className="spacer"></div>
      <div id="main" aria-label="main-view" style={{ backgroundColor: "#ffffff" }}>
        <HeroImage />
      </div>
    </div>
  );
};

export default Home;
